import { CodeHighlight } from "../../../../src/components/CodeHighlight";
import { Link } from 'gatsby';
import { Button, ButtonTest } from "../../../../content/articles/DemoComponents/FeedbackButton";
import * as React from 'react';
export default {
  CodeHighlight,
  Link,
  Button,
  ButtonTest,
  React
};