import firebase from '@firebase/app';
import '@firebase/firestore';

var firebaseConfig = {
  // Initialize Firebase
  apiKey: 'AIzaSyDT5t8RveOEVleFje9Y8N7ZjPYGMLpRDWU',
  authDomain: 'adamwebsterme-e6ef4.firebaseapp.com',
  databaseURL: 'https://adamwebsterme-e6ef4.firebaseio.com/',
  projectId: 'adamwebsterme-e6ef4',
  storageBucket: 'adamwebsterme-e6ef4.appspot.com',
  messagingSenderId: '412949202382',
  appId: '1:412949202382:web:af750b01529e6f1dd9979f',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//firebase.analytics()

export default firebase;
